import React, { useState, useEffect } from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import { Helmet } from "react-helmet"
import "../styles/new-space.scss"
import SEOHeader from "../components/seo-header"
import SimpleMap from "../components/simplemap"
import scrollTo from "gatsby-plugin-smoothscroll"
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"
import EnquiryForm from "../components/enquiry"
import NearBy from "../components/nearby"
import VizSensor from "react-visibility-sensor"
import moment from "moment"
import { nodeName } from "jquery"
import RelatedSpaces from "../components/related-spaces"
import SearchListingCard from "../components/Card/buildingcard"
import BestCoworkingForm from "../components/bestCoworkingForm"
const BuildingLevelPages = props => {
  const [currentTab, setcurrentTab] = useState("overview")
  const [scrollPosition, setPosition] = useState({ scrollX: 0, scrollY: 0 })
  const [showPhotos, setshowPhotos] = useState(false)
  const [reviewTab, setReviewTab] = useState("GoFloaters")
  const [spaceTab, setSpaceTab] = useState("meeting")
  const [meetingSpaceStartPrice, setmeetingSpaceStartPrice] = useState(0)
  const [coworkingSpaceStartPrice, setcoworkingSpaceStartPrice] = useState(0)
  const [officeSpaceStartPrice, setofficeSpaceStartPrice] = useState(0)
  const [FAQ, setFAQ] = useState(0)
  useEffect(() => {
    function updatePosition() {
      setPosition({ scrollX: window.scrollX, scrollY: window.scrollY })
    }

    window.addEventListener("scroll", updatePosition)
    updatePosition()

    return () => window.removeEventListener("scroll", updatePosition)
  }, [])
  // console.log(scrollPosition)
  const post = props.data.listings
  const meetingSpace = props.data.meetingSpace.edges
  const firstNode = meetingSpace[0]?.node // Access the first element's node

  console.log("firstNodePriceHr", meetingSpace[0]?.node.priceHr) // Log or use it as needed
  const meetingSpaceOrders = props.data.meetingSpace.distinct.sort(
    (a, b) => a - b
  )
  const coworkingSpace = props.data.coworkingSpace.edges
  const coworkingSpaceOrders = props.data.coworkingSpace.distinct.sort(
    (a, b) => a - b
  )
  const officeSpaces = props.data.officeSpaces.edges
  const officeSpacesOrders = props.data.officeSpaces.distinct.sort(
    (a, b) => a - b
  )
  useEffect(() => {
    setmeetingSpaceStartPrice(
      meetingSpace[0]?.node.priceHr ? meetingSpace[0]?.node.priceHr : 0
    )
    setcoworkingSpaceStartPrice(
      coworkingSpace[0]?.node.priceDay ? coworkingSpace[0]?.node.priceDay : 0
    )
    setofficeSpaceStartPrice(
      officeSpaces[0]?.node.priceMonth ? officeSpaces[0]?.node.priceMonth : 0
    )
  }, [])
  //const center = { lat: post.location[0], lng: post.location[1] }
  var activeClass = ""

  const Photos = post.photos?.map((photo, index) => (
    <div className="CoworkingimageBox">
      <img
        src={photo?.replace(
          "https://firebasestorage.googleapis.com/v0/b/gofloaters.appspot.com/o",
          "https://cdn.app.gofloaters.com"
        )}
        className="d-block w-100 img-responsive"
        alt={post.spaceGFName + " " + post.spaceDisplayName}
      ></img>
    </div>
  ))

  const meetingContent = (
    <div className="row flex-nowrap overflow-auto">
      {meetingSpace.map((list, i) => {
        const meetingSpaceData = list.node

        return (
          <SearchListingCard
            key={meetingSpaceData.spaceId}
            spacetype={meetingSpaceData.spaceType}
            listingImg={meetingSpaceData.spaceImage}
            title={meetingSpaceData.spaceTitle}
            gftitle={meetingSpaceData.spaceGFName}
            spaceAddress={meetingSpaceData.spaceAddress}
            spaceDesc={meetingSpaceData.spaceDesc}
            priceHr={meetingSpaceData.priceHr}
            priceDay={meetingSpaceData.priceDay}
            priceMonth={meetingSpaceData.priceMonth}
            purposesList={meetingSpaceData.purposesList}
            monthPassAvailable={meetingSpaceData.monthPassAvailable}
            dayPassAvailable={meetingSpaceData.dayPassAvailable}
            hourPassAvailable={meetingSpaceData.hourPassAvailable}
            spaceId={meetingSpaceData.spaceId}
            officeSpaceType={meetingSpaceData.officeSpaceType}
            spaceDisplayName={meetingSpaceData.spaceDisplayName}
            Distance={meetingSpaceData.Distance}
            seatsAvailable={meetingSpaceData.seatsAvailable}
            Rating={meetingSpaceData.Rating}
            Facility={meetingSpaceData.Facility}
            OriginalName={meetingSpaceData.OriginalName}
            operationTime={meetingSpaceData.operationTime}
            Slug={"/meeting-space/" + meetingSpaceData.slug}
            hasCovidSafeBadge={meetingSpaceData.hasCovidSafeBadge}
            online="true"
            type="meeting"
          ></SearchListingCard>
        )
      })}
    </div>
  )

  const coworkingContent = (
    <div className="row flex-nowrap overflow-auto">
      {coworkingSpace.map((list, i) => {
        const coworkingSpaceData = list.node
        return (
          <SearchListingCard
            key={coworkingSpaceData.spaceId}
            spacetype={coworkingSpaceData.spaceType}
            listingImg={coworkingSpaceData.spaceImage}
            title={coworkingSpaceData.spaceTitle}
            gftitle={coworkingSpaceData.spaceGFName}
            spaceAddress={coworkingSpaceData.spaceAddress}
            spaceDesc={coworkingSpaceData.spaceDesc}
            priceHr={coworkingSpaceData.priceHr}
            priceDay={coworkingSpaceData.priceDay}
            priceMonth={coworkingSpaceData.priceMonth}
            monthPassAvailable="false"
            dayPassAvailable="true"
            hourPassAvailable={coworkingSpaceData.hourPassAvailable}
            spaceId={coworkingSpaceData.spaceId}
            officeSpaceType={coworkingSpaceData.officeSpaceType}
            spaceDisplayName={coworkingSpaceData.spaceDisplayName}
            Distance={coworkingSpaceData.Distance}
            Rating={coworkingSpaceData.Rating}
            Facility={coworkingSpaceData.Facility}
            OriginalName={coworkingSpaceData.OriginalName}
            Slug={"/coworking-space/" + coworkingSpaceData.slug}
            hasCovidSafeBadge={coworkingSpaceData.hasCovidSafeBadge}
            online="true"
            type="coworking"
          ></SearchListingCard>
        )
      })}
    </div>
  )

  const officeSpacesContent = (
    <div className="row flex-nowrap overflow-auto">
      {officeSpaces.map((list, i) => {
        const officeSpaceData = list.node
        return (
          <SearchListingCard
            key={officeSpaceData.spaceId}
            spacetype={officeSpaceData.spaceType}
            listingImg={officeSpaceData.spaceImage}
            title={officeSpaceData.spaceTitle}
            gftitle={officeSpaceData.spaceGFName}
            spaceAddress={officeSpaceData.spaceAddress}
            spaceDesc={officeSpaceData.spaceDesc}
            priceHr={officeSpaceData.priceHr}
            priceDay={officeSpaceData.priceDay}
            priceMonth={officeSpaceData.priceMonth}
            monthPassAvailable="true"
            dayPassAvailable="false"
            hourPassAvailable={officeSpaceData.hourPassAvailable}
            spaceId={officeSpaceData.spaceId}
            officeSpaceType={officeSpaceData.officeSpaceType}
            spaceDisplayName={officeSpaceData.spaceDisplayName}
            Distance={officeSpaceData.Distance}
            Rating={officeSpaceData.Rating}
            Facility={officeSpaceData.Facility}
            OriginalName={officeSpaceData.OriginalName}
            Slug={"/office-space/" + officeSpaceData.slug}
            hasCovidSafeBadge={officeSpaceData.hasCovidSafeBadge}
            online="true"
            type="office"
            customButtonName="Enquiry Now"
          ></SearchListingCard>
        )
      })}
    </div>
  )

  const Photos2 = post.photos.map((photo, index) => (
    <div className="col-md-9">
      <img
        src={photo?.replace(
          "https://firebasestorage.googleapis.com/v0/b/gofloaters.appspot.com/o",
          "https://cdn.app.gofloaters.com"
        )}
        className="img-responsive"
        alt={post.spaceGFName + " " + post.spaceDisplayName}
      ></img>
    </div>
  ))

  const TimeFormat = time => {
    const t = new Date(time)
    const formatted = moment(t).format("MMMM d, YYYY")
    return formatted
  }

  const ratingText = rating => {
    if (rating > 4.5) {
      return "Very Good"
    } else if (rating >= 3.5 && rating <= 4.5) {
      return "Good"
    } else if (rating >= 2.5 && rating <= 3.5) {
      return "Average"
    } else if (rating < 2.5) {
      return "Below Average"
    }
  }

  const spaceFeedbackComp = post.spaceFeedback
    ?.sort((a, b) => (a.feedbackDate < b.feedbackDate ? 1 : -1))
    .map(dataDrop => {
      if (dataDrop.customerName) {
        return (
          <div className="ratingCard">
            <div className="row">
              <div className="col-md-12">
                <p className="customerName">{dataDrop.customerName}</p>
              </div>
              <div className="col-md-12">
                <p>{dataDrop.commentForSpaces}</p>
              </div>
              <div className="col-md-9 col-6">
                <p>
                  <div>
                    <span
                      class={
                        "stars-container stars-" +
                        Number(20) * Number(dataDrop.rating)
                      }
                    >
                      ★★★★★
                    </span>
                  </div>
                </p>
              </div>
              <div className="col-md-3 col-6 text-right">
                <p style={{ fontSize: "12px" }}>
                  {TimeFormat(dataDrop.feedbackDate)}
                </p>
              </div>
            </div>
          </div>
        )
      }
    })
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  }

  let SpaceSuitedFor = ""
  if (post.purposesList) {
    SpaceSuitedFor = post.purposesList.map((purpose, index) => (
      <div className="SpaceSuitedForAlter" key={index}>
        <p>{purpose}</p>
      </div>
    ))
  }

  let operationTime = post.operationTime

  if (post.operationTime !== "") {
    operationTime = post.operationTime
      .split(", ")
      .map((operationTime, index) => (
        <div className="col-md-4  ">
          <div className="OperationTime row aligner" key={index}>
            <div className="col-2 text-center">
              {" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 512 512"
              >
                {" "}
                <path d="M256 0a256 256 0 1 1 0 512A256 256 0 1 1 256 0zM232 120V256c0 8 4 15.5 10.7 20l96 64c11 7.4 25.9 4.4 33.3-6.7s4.4-25.9-6.7-33.3L280 243.2V120c0-13.3-10.7-24-24-24s-24 10.7-24 24z" />
              </svg>{" "}
            </div>
            <div className="col-10">
              <p className="OperationTimeText">
                <span>{operationTime.split(":")[0]}</span>
                <br></br>
                {operationTime.split(" : ")[1]}
              </p>
            </div>
          </div>
        </div>
      ))
  }

  var Title = ""
  if (post.spaceType === "Cafe") {
    Title = post.OriginalName + "," + post.spaceAddress + ", " + post.spaceCity
  } else {
    Title = post.OriginalName + "," + post.spaceAddress + ", " + post.spaceCity
  }
  function tConv24(time24) {
    var ts = time24
    var H = +ts.substr(0, 2)
    var h = H % 12 || 12
    h = h < 10 ? "0" + h : h // leading 0 at the left for 1 digit hours
    var ampm = H < 12 ? " AM" : " PM"
    ts = h + ts.substr(2, 3) + ampm
    return ts
  }
  var today = new Date()
  var day = today.getDay()

  var daylist = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ]
  var todayDay = daylist[day]
  function timeCal(todayDay) {
    switch (todayDay) {
      case "Monday":
        if (post.monday.holiday) return "Holiday"
        else {
          return tConv24(post.monday.from) + " - " + tConv24(post.monday.to)
        }
      case "Tuesday":
        if (post.tuesday.holiday) return "Holiday"
        else {
          return tConv24(post.tuesday.from) + " - " + tConv24(post.tuesday.to)
        }
      case "Wednesday":
        if (post.wednesday.holiday) return "Holiday"
        else {
          return (
            tConv24(post.wednesday.from) + " - " + tConv24(post.wednesday.to)
          )
        }
      case "Thursday":
        if (post.thursday.holiday) return "Holiday"
        else {
          return tConv24(post.thursday.from) + " - " + tConv24(post.thursday.to)
        }
      case "Friday":
        if (post.friday.holiday) return "Holiday"
        else {
          return tConv24(post.friday.from) + " - " + tConv24(post.friday.to)
        }
      case "Saturday":
        if (post.saturday.holiday) return "Holiday"
        else {
          return tConv24(post.saturday.from) + " - " + tConv24(post.saturday.to)
        }
      case "Sunday":
        if (post.sunday.holiday) return "Holiday"
        else {
          return tConv24(post.sunday.from) + " - " + tConv24(post.sunday.to)
        }
      default:
        return ""
    }
  }
  return (
    <div>
      <SEOHeader
        title={Title + "  " + post.spaceCity + " | GoFloaters"}
        description={Title + " : " + post.spaceDesc}
        socialURL={post.spaceImage}
        pinterest="true"
      ></SEOHeader>
      <Helmet>
        <script
          src="https://code.jquery.com/jquery-3.4.1.slim.min.js"
          integrity="sha384-J6qa4849blE2+poT4WnyKhv5vZF5SrPo0iEjwBvKU7imGFAV0wwj1yYfoRSJoZ+n"
          crossorigin="anonymous"
          defer
        ></script>
        <script
          src="https://cdn.jsdelivr.net/npm/popper.js@1.16.0/dist/umd/popper.min.js"
          integrity="sha384-Q6E9RHvbIyZFJoft+2mJbHaEWldlvI9IOYy5n3zV9zzTtmI3UksdQRVvoxMfooAo"
          crossorigin="anonymous"
          defer
        ></script>
        <script
          src="https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/js/bootstrap.min.js"
          integrity="sha384-wfSDF2E50Y2D1uUdj0O3uMBJnjuUD4Ih7YwaYd1iqfktj0Uod8GCExl3Og8ifwB6"
          crossorigin="anonymous"
          defer
        ></script>
      </Helmet>
      <Layout>
        <div
          itemscope
          itemtype="https://schema.org/LocalBusiness"
          className="container newspaceDetailContainer"
        >
          <div className="row">
            <div className="col-md-12 addressBar">
              <ol itemscope itemtype="https://schema.org/BreadcrumbList">
                <li
                  style={{ paddingLeft: "0" }}
                  itemprop="itemListElement"
                  itemscope
                  itemtype="https://schema.org/ListItem"
                >
                  <Link itemprop="item" to={"/" + post.spaceCity.toLowerCase()}>
                    <span itemprop="name">{post.spaceCity}</span>
                  </Link>
                  <meta itemprop="position" content="1" />
                </li>
                ›
                <li
                  itemprop="itemListElement"
                  itemscope
                  itemtype="https://schema.org/ListItem"
                >
                  <span itemprop="name">{post.spaceAddress}</span>

                  <meta itemprop="position" content="2" />
                </li>
                ›
                <li
                  itemprop="itemListElement"
                  itemscope
                  itemtype="https://schema.org/ListItem"
                >
                  <span itemprop="name">{post.OriginalName}</span>
                  <meta itemprop="position" content="3" />
                </li>
              </ol>
            </div>
          </div>
          <div className="row">
            <div className="col-md-8">
              {post.spaceType === "Cafe" ? (
                <h1 className="coworkingTitle" itemprop="name">
                  {post.OriginalName}
                </h1>
              ) : (
                <h1 className="coworkingTitle" itemprop="name">
                  {post.OriginalName}
                </h1>
              )}
              {post.spaceType !== "Cafe" ? (
                <h2 className="coworkingLocationTitle">
                  {post.spaceAddress}, {post.spaceCity}
                  <br></br>
                </h2>
              ) : (
                <h2 className="coworkingLocationTitle">
                  {post.spaceCity}, {post.spaceCity}
                  <br></br>
                </h2>
              )}
            </div>
            <div className="col-md-4 desktopRight">
              {post.avgRating ? (
                <div>
                  <p>
                    <div
                      itemprop="aggregateRating"
                      itemscope
                      itemtype="https://schema.org/AggregateRating"
                      className="aggregateRating"
                    >
                      <span style={{ color: "#101010" }}>
                        {" "}
                        {post.avgRating}
                      </span>{" "}
                      <span
                        class={
                          "stars-container stars-" +
                          Number(20) * Number(post.avgRating)
                        }
                      >
                        ★★★★★
                      </span>
                      {"   "}
                      <br />
                      <p>
                        <span itemprop="reviewCount">
                          {Number(post.spaceFeedback.length) +
                            Number(post.googleReviewCount)}
                        </span>{" "}
                        review
                        {post.spaceFeedback.length > 1 ? "s" : ""}
                      </p>
                    </div>
                  </p>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          {showPhotos ? (
            <div
              className="modal fade show "
              id="weAreClosed"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="weAreClosed"
              aria-hidden="true"
              style={{ display: "block" }}
            >
              <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content" style={{ background: "#fff" }}>
                  <div className="modal-header">
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={() => {
                        setshowPhotos(false)
                      }}
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body" style={{ padding: 15 }}>
                    <Carousel
                      swipeable={true}
                      draggable={true}
                      showDots={true}
                      responsive={responsive}
                      ssr={true} // means to render carousel on server-side.
                      autoPlay={false}
                      autoPlaySpeed={3000}
                      keyBoardControl={true}
                      infinite={true}
                      containerClassName="carousel-container"
                      removeArrowOnDeviceType={["tablet", "mobile"]}
                      deviceType={props.deviceType}
                      dotListClassName="custom-dot-list-style"
                    >
                      {Photos}
                    </Carousel>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}

          <div className="container DesktopOnly specialPadding">
            <div className="row noPadding">
              <div className="col-md-8 noPadding">
                <img
                  data-src={post.photos[0]}
                  alt={post.spaceDisplayName}
                  className="lazyload img-responsive"
                ></img>

                <div
                  className="viewAllPhotos"
                  onClick={() => {
                    setshowPhotos(true)
                  }}
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4 16L8.586 11.414C8.96106 11.0391 9.46967 10.8284 10 10.8284C10.5303 10.8284 11.0389 11.0391 11.414 11.414L16 16M14 14L15.586 12.414C15.9611 12.0391 16.4697 11.8284 17 11.8284C17.5303 11.8284 18.0389 12.0391 18.414 12.414L20 14M14 8H14.01M6 20H18C18.5304 20 19.0391 19.7893 19.4142 19.4142C19.7893 19.0391 20 18.5304 20 18V6C20 5.46957 19.7893 4.96086 19.4142 4.58579C19.0391 4.21071 18.5304 4 18 4H6C5.46957 4 4.96086 4.21071 4.58579 4.58579C4.21071 4.96086 4 5.46957 4 6V18C4 18.5304 4.21071 19.0391 4.58579 19.4142C4.96086 19.7893 5.46957 20 6 20Z"
                      stroke="#2193B0"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  View all photos
                </div>
              </div>
              <div className="col-md-4  noPadding">
                {post.photos[1] ? (
                  <img
                    data-src={post.photos[1]}
                    alt={post.spaceDisplayName}
                    className="lazyload img-responsive"
                    style={{ marginBottom: "5px" }}
                  ></img>
                ) : (
                  <img
                    data-src="https://assets.gofloaters.com/updates/common.png"
                    className="lazyload img-responsive "
                    style={{ marginBottom: "5px" }}
                  ></img>
                )}
                {post.photos[2] ? (
                  <img
                    data-src={post.photos[2]}
                    alt={post.spaceDisplayName}
                    className="lazyload img-responsive"
                    style={{ marginBottom: "5px" }}
                  ></img>
                ) : (
                  <img
                    data-src="https://assets.gofloaters.com/updates/meeting-spaces%20-placeholder.png"
                    className="lazyload img-responsive"
                    style={{ marginBottom: "5px" }}
                  ></img>
                )}
              </div>
            </div>
          </div>
          <div className="row ">
            <div className="col-md-12 MobileOnly">
              <Carousel
                swipeable={true}
                draggable={true}
                showDots={true}
                responsive={responsive}
                ssr={true} // means to render carousel on server-side.
                autoPlay={false}
                autoPlaySpeed={3000}
                keyBoardControl={true}
                containerClassName="carousel-container"
                removeArrowOnDeviceType={["tablet", "mobile"]}
                deviceType={props.deviceType}
                dotListClassName="custom-dot-list-style"
              >
                {Photos}
              </Carousel>
              <br />
            </div>
            <div className="container">
              <div className="row">
                <div className="col-md-8">
                  <h4 style={{ paddingTop: "10px" }}>
                    Space options available in this building:
                  </h4>
                  {officeSpaces.length ? (
                    <div className="WorkSpaceOption">
                      <p className="bold">Office Spaces </p>

                      {officeSpacesContent}
                    </div>
                  ) : (
                    ""
                  )}
                  {meetingSpace.length ? (
                    <div className="WorkSpaceOption">
                      <p className="bold">Meeting Rooms </p>
                      {/* <p>Dedicated space for productive meetings.</p>
                      <p>
                        <b>Seat Capcity: </b>
                        {meetingSpaceOrders.toString()}
                      </p>
                      <p>
                        <b>Price Start from</b>: ₹{meetingSpaceStartPrice}
                      </p> */}
                      {meetingContent}
                    </div>
                  ) : (
                    ""
                  )}
                  {coworkingSpace.length ? (
                    <div className="WorkSpaceOption">
                      <p className="bold">Coworking Day Pass</p>
                      {/* <p>Coworking space with full amenities.</p>
                      <p>
                        <b>Price Start from</b>: ₹{coworkingSpaceStartPrice}
                      </p> */}
                      {coworkingContent}
                    </div>
                  ) : (
                    ""
                  )}
                  <div
                    style={{
                      padding: "10px 15px 20px",
                      border: "1px solid #2193b0",
                      borderRadius: "10px",
                      backgroundImage:
                        "linear-gradient(rgba(234,202,175,0.49), rgba(188,175,146,.1))",
                    }}
                  >
                    <div className="row">
                      <div className="col-md-12">
                        <h4 id="amenities">GoFloaters Advantage</h4>
                      </div>
                      <div className="col-md-6 col-12">
                        <div className="row aligner paddingTriggerTwo">
                          <div className="col-md-3 col-3 text-center">
                            <img
                              src="https://assets.gofloaters.com/icons/svg/lower-price.svg"
                              style={{ width: "36px" }}
                            ></img>
                          </div>
                          <div className="col-md-9 col-9">
                            <h2
                              style={{ fontWeight: "bold", fontSize: "16px" }}
                            >
                              Lowest Price Guaranteed
                            </h2>
                            {/* <p>
                            You can trust us with our fair and dependable
                            prices.
                          </p> */}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 col-12">
                        <div className="row aligner paddingTriggerTwo">
                          <div className="col-md-3 col-3 text-center">
                            <img
                              src="https://assets.gofloaters.com/icons/svg/free-rescheduling.svg"
                              style={{ width: "36px" }}
                            ></img>
                          </div>
                          <div className="col-md-9 col-9">
                            <h2
                              style={{ fontWeight: "bold", fontSize: "16px" }}
                            >
                              Free Rescheduling
                            </h2>
                            {/* <p>
                            You can modify the date,time and other details of
                            your scheduled booking .
                          </p> */}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 col-12">
                        <div className="row aligner paddingTriggerTwo">
                          <div className="col-md-3 col-3 text-center">
                            <img
                              src="https://assets.gofloaters.com/icons/svg/free-cancellation.svg"
                              style={{ width: "36px" }}
                            ></img>
                          </div>
                          <div className="col-md-9 col-9">
                            <h2
                              style={{ fontWeight: "bold", fontSize: "16px" }}
                            >
                              Free Cancellation
                            </h2>
                            {/* <p>You can cancel booking upto 48 hours</p> */}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 col-12">
                        <div className="row aligner paddingTriggerTwo">
                          <div className="col-md-3 col-3 text-center">
                            <img
                              src="https://assets.gofloaters.com/icons/svg/instant-confirmations.png"
                              style={{ width: "36px" }}
                            ></img>
                          </div>
                          <div className="col-md-9 col-9">
                            <h2
                              style={{ fontWeight: "bold", fontSize: "17px" }}
                            >
                              Instant Confirmations
                            </h2>
                            {/* <p>You can cancel booking upto 48 hours</p> */}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 col-12">
                        <div className="row aligner paddingTriggerTwo">
                          <div className="col-md-3 col-3 text-center">
                            <img
                              src="https://assets.gofloaters.com/icons/svg/verified-listings.png"
                              style={{ width: "36px" }}
                            ></img>
                          </div>
                          <div className="col-md-9 col-9">
                            <h2
                              style={{ fontWeight: "bold", fontSize: "17px" }}
                            >
                              Verified listings
                            </h2>
                            {/* <p>You can cancel booking upto 48 hours</p> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <br></br>
                  {post.spaceDesc ? (
                    <div id="overview">
                      <br></br>
                      <h4>About this space</h4>{" "}
                      <VizSensor
                        onChange={() => {
                          setcurrentTab("overview")
                        }}
                      >
                        <p itemprop="description">{post.spaceDesc}</p>
                      </VizSensor>
                    </div>
                  ) : (
                    ""
                  )}
                  <div>
                    <br></br>
                    <h4 id="opening">Opening Hours</h4>
                    <VizSensor
                      onChange={() => {
                        setcurrentTab("opening")
                      }}
                    >
                      <b>Today ({todayDay})</b>
                    </VizSensor>
                    <br />
                    {timeCal(todayDay)}
                    <br />
                    <br />
                    <div
                      className="row"
                      style={{
                        padding: "10px 5px",
                        margin: "0",
                        border: "1px solid #D9D9D9",
                      }}
                    >
                      {operationTime}
                    </div>
                  </div>
                  <br></br>
                  <h4>Location</h4>
                  <VizSensor
                    onChange={() => {
                      setcurrentTab("location")
                    }}
                  >
                    {post.geoLocation !== "" ? (
                      <SimpleMap
                        lat={post.geoLocation[0]}
                        lng={post.geoLocation[1]}
                      ></SimpleMap>
                    ) : (
                      "<p></p>"
                    )}
                  </VizSensor>
                  <br></br>
                  <br></br>
                  <a
                    href={
                      "https://maps.google.com/?q=" +
                      post.geoLocation[0] +
                      "," +
                      post.geoLocation[1]
                    }
                    target="_blank"
                  >
                    View on map <i className="fa fa-chevron-right"></i>
                  </a>
                  <br></br>{" "}
                  {post.nearBy.length > 1 ? <h4>Whats’s Nearby</h4> : ""}
                  <div className="row">
                    {post.nearBy
                      ? post.nearBy.map(near => (
                          <div className="col-md-6 col-6">
                            <div className="row">
                              <div className="col-6">{near.name}</div>
                              <div className="col-6">{near.distance} km</div>
                            </div>
                          </div>
                        ))
                      : ""}
                  </div>
                  <div className="row" id="rating">
                    <VizSensor
                      onChange={() => {
                        setcurrentTab("rating")
                      }}
                    >
                      <div className="col-md-8">
                        {/* <br></br>
                        <h4>Reviews</h4> */}
                        {/* <ul className="reviewTab">
                          {post.spaceFeedback.length > 0 ? (
                            <li
                              onClick={() => {
                                setReviewTab("GoFloaters")
                              }}
                              className={reviewTab === "GoFloaters" && "active"}
                            >
                              <p>
                                GoFloaters Review ({post.spaceFeedback.length})
                              </p>
                            </li>
                          ) : (
                            ""
                          )}
                          {post.googleReviewCount > 0 ? (
                            <li
                              onClick={() => {
                                setReviewTab("Google")
                              }}
                              className={reviewTab === "Google" && "active"}
                            >
                              <p>Google Review ({post.googleReviewCount})</p>
                            </li>
                          ) : (
                            ""
                          )}
                        </ul>
                        {reviewTab === "GoFloaters" &&
                        post.spaceFeedback.length > 0 ? (
                          <div>
                            <p className="customerRatingTime">
                              {post.spaceFeedback.length} reviews
                            </p>
                            {post.spaceFeedback.length > 0
                              ? spaceFeedbackComp
                              : "No Review"}
                          </div>
                        ) : (
                          <div>
                            <br />
                            <p style={{ fontSize: "18px" }}>
                              <b style={{ fontSize: "24px", color: "#1e8aa5" }}>
                                {post.googleRating}
                              </b>{" "}
                              Rating from{" "}
                              <b style={{ color: "#1e8aa5" }}>
                                {post.googleReviewCount}
                              </b>{" "}
                              reviews
                            </p>
                            <br />

                            <p>
                              {post.googleMapsLink ? (
                                <a
                                  style={{ fontSize: "18px", color: "#1e8aa5" }}
                                  href={post.googleMapsLink}
                                  target="_blank"
                                >
                                  {" "}
                                  Visit Google Map Review &gt;&gt;
                                </a>
                              ) : (
                                ""
                              )}
                            </p>
                          </div>
                        )}
                       */}
                      </div>
                    </VizSensor>
                  </div>
                  <br></br>
                </div>
                <div className="col-md-4">
                  <div className="sticky">
                    <h4 style={{ paddingTop: "10px" }}>Want to know more?</h4>
                    <p> Leave your enquiry with us</p>
                    <BestCoworkingForm type="workspaceform" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br></br>
      </Layout>
    </div>
  )
}
export default BuildingLevelPages
export const query = graphql`
  query BuildingLevelPages {
    listings(OriginalName: { eq: "WeWork Prestige Atlanta" }) {
      spaceDisplayName
      OriginalName
      spaceCity
      spaceGFName
      spaceId
      spaceImage
      spaceTitle
      spaceType
      subType
      monthPassAvailable
      officeSpaceType
      priceDay
      priceHr
      priceMonth
      photos
      Facility
      operationTime
      hourPassAvailable
      exclusiveOffer
      dayPassAvailable
      purposesList
      spaceAddress
      noiseLevel
      landmark
      spaceDesc
      hasCovidSafeBadge
      securitydeposit
      seat
      slug
      geoLocation
      spaceOverview
      Rating
      priceperdayPreDiscount
      priceperhrPreDiscount
      googleMapsLink
      googleRating
      googleReviewCount
      avgRating
      meetingRoomName
      spaceFeedback {
        customerName
        feedbackDate
        gofloaterrating
        rating
        spaceId
        spaceName
        commentForSpaces
        commentForFloaters
      }
      online
      monday {
        from
        to
        useNow
        holiday
      }
      tuesday {
        from
        to
        useNow
        holiday
      }
      wednesday {
        from
        to
        useNow
        holiday
      }
      thursday {
        from
        to
        useNow
        holiday
      }
      friday {
        from
        to
        useNow
        holiday
      }
      saturday {
        from
        to
        useNow
        holiday
      }
      sunday {
        from
        to
        useNow
        holiday
      }
      nearBy {
        distance
        name
        type
      }
      faqs {
        answer
        question
      }
    }
    meetingSpace: allListings(
      filter: {
        spaceType: { eq: "Conference Room" }
        slug: { ne: "" }
        online: { eq: true }
        OriginalName: { eq: "WeWork Prestige Atlanta" }
      }
      sort: { fields: priceHr, order: ASC }
    ) {
      totalCount
      edges {
        node {
          monthPassAvailable
          dayPassAvailable
          hourPassAvailable
          officeSpaceType
          purposesList
          spaceAddress
          spaceGFName
          OriginalName
          spaceCity
          spaceId
          spaceImage
          spaceTitle
          spaceDesc
          spaceType
          subType
          priceDay
          priceHr
          priceMonth
          spaceDisplayName
          Facility
          slug
          hasCovidSafeBadge
          online
          Rating
          pincode
          bookingcount
          isFeatured
          priceperdayPreDiscount
          priceperhrPreDiscount
          operatorName
          meetingRoomName
        }
      }
      distinct(field: seat)
    }
    coworkingSpace: allListings(
      filter: {
        spaceType: { eq: "Shared Workspace" }
        dayPassAvailable: { eq: true }
        slug: { ne: "" }
        online: { eq: true }
        OriginalName: { eq: "WeWork Prestige Atlanta" }
      }
      sort: { fields: priceDay, order: ASC }
    ) {
      totalCount
      edges {
        node {
          monthPassAvailable
          dayPassAvailable
          hourPassAvailable
          officeSpaceType
          purposesList
          spaceAddress
          spaceGFName
          OriginalName
          spaceCity
          spaceId
          spaceImage
          spaceTitle
          spaceDesc
          spaceType
          subType
          priceDay
          priceHr
          priceMonth
          spaceDisplayName
          Facility
          slug
          hasCovidSafeBadge
          online
          Rating
          bookingcount
          needsBookingConfirmation
          isFeatured
          priceperdayPreDiscount
          priceperhrPreDiscount
          operatorName
        }
      }
      distinct(field: seat)
    }
    officeSpaces: allListings(
      filter: {
        spaceType: { eq: "Shared Workspace" }
        monthPassAvailable: { eq: true }
        slug: { ne: "" }
        online: { eq: true }
        OriginalName: { eq: "WeWork - Prestige Atlanta" }
      }
      sort: { fields: priceMonth, order: ASC }
    ) {
      totalCount
      edges {
        node {
          monthPassAvailable
          dayPassAvailable
          hourPassAvailable
          officeSpaceType
          purposesList
          spaceAddress
          spaceGFName
          OriginalName
          spaceCity
          spaceId
          spaceImage
          spaceTitle
          spaceDesc
          spaceType
          subType
          priceDay
          priceHr
          priceMonth
          spaceDisplayName
          Facility
          slug
          hasCovidSafeBadge
          online
          Rating
          bookingcount
          isFeatured
          priceperdayPreDiscount
          priceperhrPreDiscount
        }
      }
      distinct(field: seat)
    }
  }
`
